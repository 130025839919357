@import '../../../styles/gifts/sass/basics';

.bb-hero-image {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  position: relative;

  &__label {
    font-weight: bold;
    font-size: $font-size-base;
    display: inline-block;
    background-color: $green-600;
    padding: 4px 16px;
    max-width: 700px;
    margin-bottom: 14px;
    color: $white;
  }

  &__image-container {
    position: relative;

    @media all and (min-resolution: 1x) {
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
      }
      // 1440x565 image. 565 / 1440 * 100 ~ 39.24
      padding-top: 39.24% !important;
    }

    .bb-hero-image__label {
      display: none;
      @media all and (max-width: 767px) {
        display: inline-block;
        position: absolute;
        bottom: 0;
        left:0;
        margin: 0;
        font-size: $font-size-small;
        padding: 4px 8px;
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    color: $white;
    margin: auto;
    background: linear-gradient(0deg, rgba(3,0,36,0.5) 0%, rgba(3,0,36,0.35) 60%, rgba(3,0,36,0) 100%);
    padding-top: 25px;
    padding-bottom: 25px;
  }
  &__title {
    width: 100%;
    display: inline-block;
    font-weight: bold;
    font-size: 42px;
    color: $white;
    margin-bottom: 11px;
  }

  &__description {
    font-size: $font-size-large;
    max-width: 700px;
    line-height: 30px;
  }

  @media all and (max-width: 767px) {
    &__content {
      position: relative;
      background: $white;
      color: $gray-1000;
      padding: 0;
    }

    :global(.container) {
      margin-top: 10px;
    }

    &__title {
      color: $gray-1000;
      font-size: $font-size-h3;
      line-height: 34px;
    }

    &__label {
      display: none;
    }

    &__description {
      font-size: $font-size-h4;
    }
  }
}
